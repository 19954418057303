




import {defineComponent} from '@vue/composition-api';
import moment from 'moment';
import 'moment-timezone';

export default defineComponent({
  name: 'Calendar',
  props: {
    value: {type: String, required: true}
  },
  setup(props) {
    return {date: moment.tz(props.value, moment.tz.guess()).calendar()};
  },
});
