import {ref} from '@vue/composition-api';
import {v4 as uuid} from 'uuid';

export const savingIndicator = ref(false);
export const savingFailed = ref(false);

const saving = new Set<string>();

export function savingPromise<T>(promise:Promise<T>){
    const id = uuid();
    startSaving(id);
    promise.catch(()=> savingFailed.value = true).finally(() => endSaving(id));
    return promise;
}

export function startSaving(id:string){
    saving.add(id);
    savingIndicator.value = true;
    setTimeout(() => {
        if(!saving.size) savingIndicator.value = false;
    }, 669);
}

export function endSaving(id:string){
    saving.delete(id);
    setTimeout(() => {
        if(!saving.size) savingIndicator.value = false;
    }, 669);
}