



import {defineComponent} from '@vue/composition-api';

export default defineComponent({
  name: 'Home',
  setup(_, {root}) {
    root.$router.push({name: 'Polls'});
  }
});
