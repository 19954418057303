type Handler = (text: string) => void;
type MessageHandler = (text: string, icon:string) => void;

const errorHandlers: Handler[] = [];
const messageHandlers: MessageHandler[] = [];

export function onError(handler: Handler) {
    errorHandlers.push(handler);
}

export function onMessage(handler: MessageHandler) {
    messageHandlers.push(handler);
}

export function displayError(text: string) {
    errorHandlers.forEach(handler => handler(text));
}

export function displayMessage(text: string, icon = 'check') {
    messageHandlers.forEach(handler => handler(text, icon));
}