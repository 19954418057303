import firebase from 'firebase/app';
import 'firebase/auth';

firebase.initializeApp({
    apiKey: "AIzaSyBbELQ2YqX-I08DWbDd5M-s0Rghglu8l-U",
    authDomain: "agreed-gcp.firebaseapp.com",
});

export function onAuthStateChanged(callback: firebase.Observer<any> | ((a: (firebase.User | null)) => any)) {
    return firebase.auth().onAuthStateChanged(callback);
}

export function getCurrentUser() {
    return firebase.auth().currentUser;
}

export async function signInWithGoogleOidcIdToken(idToken: string) {
    return await firebase.auth().signInWithCredential(firebase.auth.GoogleAuthProvider.credential(idToken));
}

export function signOut() {
    return firebase.auth().signOut();
}
