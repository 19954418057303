import 'setimmediate';
import winston from 'winston';
import TransportStream from 'winston-transport';
import Transport from 'winston-transport';
import mixpanel from 'mixpanel-browser';
import firebase from "firebase/app";

const MIXPANEL_KEY = '9c847c12e67a5aac0fbda4e8901ad0fe';
mixpanel.init(MIXPANEL_KEY);

export enum Event {
    OPEN_WEBAPP = 'Open webapp',
    INSTALL_EXTENSION = 'Install extension',
    UNINSTALL_EXTENSION = 'Uninstall extension',
    OPEN_SETTINGS_PAGE = 'Open settings page',
    INVITE_TEAMMATES = 'Invite teammates',
    OPEN_FEEDBACK_AND_SUPPORT = 'Open feedback and support',
    OPEN_NOTY_LINKEDIN = 'Open Noty Linkedin',
    OPEN_TERMS_AND_PRIVACY = 'Open Terms & Privacy',
    SEARCH_IN_TRANSCRIPT = 'Search in transcript',
    INSIGHT_COPY = 'Copy insight'
}

class MixpanelEvent extends Transport {
    constructor(opts?: TransportStream.TransportStreamOptions) {
        super(opts);
    }

    log(info: { message: { eventName: string, properties?: any } }, next: () => void) {
        if (info.message.eventName) {
            mixpanel.track(info.message.eventName, {
                ...info.message.properties, source: 'webapp', url: window.location.href
            });
        }
        next();
    }
}

const LOG = winston.createLogger();
if (process.env.NODE_ENV === 'production') LOG.add(new MixpanelEvent());
else LOG.add(new winston.transports.Console({ level: 'verbose' }));

export async function updateUserTrackingInformation(user: firebase.User) {
    if (!user.email) throw new Error('User has no email');
    mixpanel.people.set({
        $email: user.email,
        $name: user.displayName
    });
    mixpanel.identify(user.email);
}

export function trackEvent(eventName: Event, properties?: any) {
    LOG.info({ eventName, properties });
}