var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[(_vm.user)?[_c('v-app-bar',{attrs:{"app":""}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"text-h5 primary--text",attrs:{"cols":"auto"}},[_c('router-link',{staticStyle:{"text-decoration":"none","color":"inherit"},attrs:{"to":"/polls"}},[_c('v-img',{attrs:{"src":"https://agreed.solutions/logo.png","width":"200","contain":""}})],1)],1),(_vm.savingIndicator)?_c('v-col',{attrs:{"cols":"auto"}},[_c('v-chip',[_vm._v(" "+_vm._s(_vm.$t('poll.saving'))+" "),_c('v-progress-circular',{staticClass:"ml-2",attrs:{"indeterminate":"","size":"20","width":"1"}})],1)],1):(_vm.savingFailed)?_c('v-col',{staticClass:"error--text",attrs:{"cols":"auto"}},[_c('v-chip',{staticClass:"error"},[_c('v-icon',{staticClass:"px-1",attrs:{"left":"","dark":"","small":""}},[_vm._v("mdi-alert")]),_vm._v(" "+_vm._s(_vm.$t('poll.savingFailed'))+" ")],1)],1):_vm._e(),_c('v-spacer'),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-n4",attrs:{"to":"/polls","fab":"","color":"primary","icon":""}},on),[_c('v-icon',[_vm._v("mdi-vote")])],1)]}}],null,false,1980838941)},[_vm._v(" "+_vm._s(_vm.$t('poll.polls'))+" ")])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-n4",attrs:{"to":"/lists","fab":"","color":"primary","icon":""}},on),[_c('v-icon',[_vm._v("mdi-account-multiple")])],1)]}}],null,false,819683956)},[_vm._v(" "+_vm._s(_vm.$t('contacts.contacts'))+" ")])],1),_c('v-col',{attrs:{"cols":"auto"}},[_c('v-menu',{attrs:{"offset-y":"","nudge-bottom":"10","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","elevation":"0"}},on),[_c('v-avatar',{attrs:{"size":"40"}},[_c('v-img',{attrs:{"src":_vm.user.photoURL}})],1)],1)]}}],null,false,2005497364)},[_c('v-card',[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"subtitle-1"},[_vm._v(_vm._s(_vm.user.displayName))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.user.email))])],1)],1),_c('v-divider'),_c('v-list-item',{on:{"click":_vm.signOut}},[_vm._v(_vm._s(_vm.$t('user.signOut')))])],1)],1)],1)],1)],1)],1),_c('v-main',[_c('router-view',{key:_vm.$route.path})],1)]:_c('router-view'),_c('v-snackbar',{attrs:{"color":"error"},model:{value:(_vm.error),callback:function ($$v) {_vm.error=$$v},expression:"error"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-alert")]),_vm._v(" "+_vm._s(_vm.errorText)+" ")],1),_c('v-snackbar',{attrs:{"color":"success"},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-"+_vm._s(_vm.messageIcon))]),_vm._v(" "+_vm._s(_vm.messageText)+" ")],1),_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","small":"","fixed":"","right":"","bottom":""}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-help")])],1)]}}])},[_c('v-card',[_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"href":"mailto: email@noty.ai","target":"_blank"},on:{"click":_vm.openFeedbackAndSupport}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('info.feedbackSupport')))])],1)],1),_c('v-divider'),_c('v-list-item',{attrs:{"href":"https://agreed.solutions/%D0%9F%D1%80%D0%B0%D0%B2%D0%B8%D0%BB%D0%B0%20%D0%BA%D0%BE%D1%80%D0%B8%D1%81%D1%82%D1%83%D0%B2%D0%B0%D0%BD%D0%BD%D1%8F%20AGREED.pdf","target":"_blank"},on:{"click":_vm.openTermsAndPrivacy}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$t('info.termsPrivacy')))])],1)],1)],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }