import Vue from 'vue';
import VueRouter, {RouteConfig} from 'vue-router';
import Home from '../views/Home.vue';
import {getCurrentUser} from "@/auth/identity";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/login',
        name: 'Login',
        component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
        meta: {
            requiresGuest: true
        }
    },
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/polls',
        name: 'Polls',
        component: () => import(/* webpackChunkName: "login" */ '../views/Polls.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/polls/:id/edit',
        name: 'PollEdit',
        component: () => import(/* webpackChunkName: "login" */ '../views/PollEdit.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/polls/:id/vote',
        name: 'PollVote',
        component: () => import(/* webpackChunkName: "login" */ '../views/PollVote.vue')
    },

    {
        path: '/polls/:id',
        name: 'Poll',
        component: () => import(/* webpackChunkName: "login" */ '../views/Poll.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/polls/:id/participants',
        name: 'PollParticipants',
        component: () => import(/* webpackChunkName: "login" */ '../views/PollParticipants.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/lists/:id',
        name: 'ContactList',
        component: () => import(/* webpackChunkName: "login" */ '../views/ContactList.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/lists/contact/:id',
        name: 'Contact',
        component: () => import(/* webpackChunkName: "login" */ '../views/Contact.vue'),
        meta: {
            requiresAuth: true
        }
    },

    {
        path: '/lists/:id/import',
        name: 'ImportContacts',
        component: () => import(/* webpackChunkName: "login" */ '../components/ImportContacts.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/lists',
        name: 'ContactListsView',
        component: () => import(/* webpackChunkName: "login" */ '../views/ContactListsView.vue'),
        meta: {
            requiresAuth: true
        }
    },
    {
        path: '/settings',
        name: 'Settings',
        component: () => import(/* webpackChunkName: "settings" */ '../views/Settings.vue'),
        meta: {
            requiresAuth: true
        }
    }
];

const router = new VueRouter({
    mode: 'history',
    routes
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (!getCurrentUser()) {
            next({
                path: '/login',
                query: {
                    redirect: to.fullPath
                }
            });
        } else {
            next();
        }
    } else if (to.matched.some(record => record.meta.requiresGuest)) {
        if (getCurrentUser()) {
            next({
                path: '/',
                query: {
                    redirect: to.fullPath
                }
            });
        } else {
            next();
        }
    } else {
        next();
    }
});

export default router;
