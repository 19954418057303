export {SYSTEM_VERSION} from '@noty/common';

function verifyValueDefined(value: string | undefined): string {
    if (!value) throw new Error('Env variable not defined');
    return value;
}

function stringValue(value: string | undefined) {
    return verifyValueDefined(value);
}

export const EXTENSION_ID = stringValue(process.env.VUE_APP_EXTENSION_ID);
export const INSTALL_EXTENSION_URL = stringValue(process.env.VUE_APP_INSTALL_EXTENSION_URL);
export const BACKEND_URL = stringValue(process.env.VUE_APP_BACKEND_URL);
export const WEBAPP_VERSION = stringValue(process.env.WEBAPP_VERSION);
