let google: typeof gapi;

function initGoogleApi(): Promise<typeof gapi> {
    const gapiScript = document.createElement('script');
    gapiScript.src = 'https://apis.google.com/js/api.js?onload=onGapiLoad';
    return new Promise((resolve, reject) => {
        // @ts-ignore
        window.onGapiLoad = function onGapiLoad() {
            // eslint-disable-next-line no-undef
            const googleApi = gapi;
            googleApi.load('auth2', {callback: () => resolve(googleApi)});
        };
        document.body.appendChild(gapiScript);
    });
}

const initPromise = initGoogleApi().then(g => google = g).catch(console.error);

async function getAuthObject(scope: string): Promise<Omit<gapi.auth2.GoogleAuth, 'then'>> {
    await initPromise;
    return google.auth2.init({
        scope, client_id: '366557122913-nnnhvmnm8ld35g58mjjvj3464ntbibdb.apps.googleusercontent.com'
    }) as Omit<gapi.auth2.GoogleAuth, 'then'>;
}

export async function getGoogleOidcCode() {
    // const scope = 'https://www.googleapis.com/auth/drive.file';
    const scope = '';
    const auth2 = await getAuthObject(scope);
    const options = new google.auth2.SigninOptionsBuilder();
    options.setScope(scope);
    return await auth2.currentUser.get().grantOfflineAccess(scope);
}
