import {getCustomToken} from "@/auth/auth";
import firebase from 'firebase/app';
import {EXTENSION_ID, INSTALL_EXTENSION_URL} from "@/util/env";

function sendMessage<T>(type: string, expire?: number, data?: any): Promise<T> {
    return new Promise((resolve, reject) => {
        let timeout: NodeJS.Timeout;
        if (expire) timeout = setTimeout(() => reject({timeout: expire}), expire);
        chrome.runtime.sendMessage(EXTENSION_ID, {type, data}, response => {
            clearTimeout(timeout);
            if (response?.errorMessage) reject(response.errorMessage);
            else resolve(response);
        });
    })
}

export async function isExtensionInstalled(): Promise<boolean> {
    if (!chrome.runtime) return false;
    return await sendMessage<boolean>('IS_INSTALLED', 1000);
}

export async function updateExtensionAuth(user: firebase.User | null): Promise<any> {
    const isInstalled = await isExtensionInstalled();
    if (!isInstalled) throw new Error('Extension not installed');
    if (!user) {
        sendMessage('SIGN_OUT');
        return;
    }
    const currentUser = await sendMessage<{ uid?: string }>('GET_CURRENT_USER', 10000);
    if (currentUser?.uid !== user.uid) {
        const token = await getCustomToken();
        const newUser = await sendMessage('SIGN_IN', 10000, token);
        //reload to show conversations migrated from extension, remove this in April
        window.location.reload();
        return newUser;
    }
}

export function openWebStorePage() {
    window.open(INSTALL_EXTENSION_URL, '_blank');
}
