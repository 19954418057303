import './util/initCompositionApi';
import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import i18n from './i18n';
import {getCurrentUser, onAuthStateChanged} from "@/auth/identity";
import axios from "axios";
import {BACKEND_URL} from "@/util/env";
import {Event, trackEvent, updateUserTrackingInformation} from "@/util/log";
import VueGtag from "vue-gtag";

import './assets/css/main.css';

Vue.config.productionTip = false;

Vue.use(VueGtag, {
    config: { id: "G-81KX2DQRSY" }
});

axios.defaults.baseURL = BACKEND_URL;
axios.interceptors.request.use(async request => {
    const user = getCurrentUser();
    if (user) request.headers.Authorization = 'Bearer ' + (await user?.getIdToken());
    return request;
});

let app: Vue;
onAuthStateChanged(user => {
    if (user) updateUserTrackingInformation(user);
    if (!app) {
        app = new Vue({
            router,
            store,
            vuetify,
            i18n,
            render: h => h(App)
        }).$mount('#app');
        trackEvent(Event.OPEN_WEBAPP);
    }
});
