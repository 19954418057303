import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

// Translation provided by Vuetify (typescript)
import uk from 'vuetify/src/locale/uk'

export default new Vuetify({
    lang: {
        locales: { uk },
        current: 'uk',
    },
    theme: {
        themes: {
            light: {
                primary: '#673AB7'
            }
        }
    }
});
