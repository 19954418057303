import {getGoogleOidcCode} from "@/auth/gapi_wrap";
import axios from "axios";
import {signInWithGoogleOidcIdToken} from "./identity";
import {AuthResponse} from "@noty/common";

export async function googleSignIn() {
    const {code} = await getGoogleOidcCode();
    const token = await getOAuthIdToken(code);
    return await signInWithGoogleOidcIdToken(token.id_token);
}

async function getOAuthIdToken(code: string) {
    return (await axios.post<AuthResponse>('auth/oidc', {code, redirectUrl: window.location.origin})).data;
}

export async function getCustomToken() {
    return (await axios.get('auth/custom')).data;
}
