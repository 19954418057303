


































































































































import {computed, defineComponent, ref} from '@vue/composition-api';
import {getCurrentUser, onAuthStateChanged, signOut as authSignOut} from "@/auth/identity";
import HelloWorld from './components/DateTime/Calendar.vue';
import {onError, onMessage} from "@/util/error";
import {isExtensionInstalled} from "@/util/extension";
import {Event, trackEvent} from "@/util/log";
import {SYSTEM_VERSION, WEBAPP_VERSION} from "@/util/env";
import {savingFailed, savingIndicator} from "@/util/savigIndicator";

export default defineComponent({
  name: 'App',
  components: {
    HelloWorld,
  },
  setup(props, {root}) {
    const user = ref(getCurrentUser());
    onAuthStateChanged(newUser => user.value = newUser);

    const signOut = async () => {
      await authSignOut();
      await root.$router.push({name: 'Login'})
    }

    const miniNav = computed(() => root.$vuetify.breakpoint.mdAndDown);

    const error = ref(false);
    const errorText = ref('');
    onError(text => {
      errorText.value = text;
      error.value = true;
    });

    const message = ref(false);
    const messageText = ref('');
    const messageIcon = ref('');
    onMessage((text, icon) => {
      messageText.value = text;
      messageIcon.value = icon;
      message.value = true;
    });

    const openFeedbackAndSupport = () => trackEvent(Event.OPEN_FEEDBACK_AND_SUPPORT);
    const openNotyLinkedin = () => trackEvent(Event.OPEN_NOTY_LINKEDIN);
    const openTermsAndPrivacy = () => trackEvent(Event.OPEN_TERMS_AND_PRIVACY);

    const extensionInstalled = ref(true);
    isExtensionInstalled().then(b => extensionInstalled.value = b);

    const extensionJustInstalled = ref(root.$route.query.extensionInstalled);
    if (extensionJustInstalled.value) trackEvent(Event.INSTALL_EXTENSION);
    return {
      user, signOut, miniNav, error, errorText, message, messageText, messageIcon,
      savingIndicator, savingFailed,
      openFeedbackAndSupport, openNotyLinkedin, openTermsAndPrivacy,
      WEBAPP_VERSION, SYSTEM_VERSION
    };
  }
});
